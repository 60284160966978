import React, { useState, setState} from 'react'
import { TwitterShareButton, FacebookShareButton, LinkedinShareButton } from "react-share"
import { TwitterIcon, FacebookIcon, LinkedinIcon } from "react-share"
import styled from "styled-components"
import sharebtn from '../../assets/link.svg'

const SocialShare = ({title, url, description}) => {
    const [copied, setCopied] = useState(false)
    const ShareButton = styled.button`
        border: none;
        width: 32px;
        border-radius: 50%;
        outline: none;
        background-color: ${props => props.color ? props.color : "#DDD"};
        ::before {
            content: url(${sharebtn});
            display: inline-flex;
            vertical-align: middle;
        }
        `;
    return (
        <>
            <TwitterShareButton title={title} url={url}>
                <TwitterIcon size={32} round />
            </TwitterShareButton>
            <FacebookShareButton quote={title} url={url}>
                <FacebookIcon size={32} round />
            </FacebookShareButton>
            <LinkedinShareButton title={title} url={url} summary={description} source={"BooksChatter"}>
                <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            {!copied ?
            <ShareButton 
            title= "Copy link"
            onClick={() =>  {setCopied(true);navigator.clipboard.writeText(url)}}>
            </ShareButton>:
            <ShareButton color="orange"
            title= "Copied"></ShareButton>}
        </>
    )
}

export default SocialShare