import * as React from "react"
import { Link, graphql } from "gatsby"
import RehypeReact from "rehype-react"

import SocialShare from "../../components/socialshare"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { BuyButton } from "./blog-styles";
import './styles.css'

const renderAst = new RehypeReact({
    createElement: React.createElement,
    components: {
        button: BuyButton,
      },
  }).Compiler;

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const siteUrl = data.site.siteMetadata.siteUrl
  const { previous, next } = data
  const featuredImage = siteUrl + post.frontmatter.featuredImage.publicURL
  const blogUrl = siteUrl + post.frontmatter.slug
  const description = post.frontmatter.description || post.excerpt

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={featuredImage || null}
        type="article"
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <div>
            <div style={{float: 'left'}}>
              <p style={{marginTop: '0px'}}>{post.frontmatter.date}&nbsp; &bull; &nbsp;{post.timeToRead} min read</p>
            </div>
            <div className="social-share">
              <SocialShare title={post.frontmatter.title} url={blogUrl} description={description} />
            </div>
          </div>
        </header>
        <div style={{clear: 'both', marginTop:'4em'}}>
          {renderAst(post.htmlAst)}
        </div>
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li style={{minWidth: '300px'}}>
            {previous && (
              <Link to={previous.frontmatter.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.frontmatter.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      htmlAst
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        slug
        featuredImage {
          publicURL
        }
      }
      timeToRead
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        slug
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        slug
      }
    }
  }
`
