import styled from "styled-components"

export const Button = styled.button`
border: none;
color: white;
padding: 0.5em;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 1em;
`;

export const BuyButton = styled(Button)`
background-color: #555555;
&:hover {
  cursor:pointer;
}
`;